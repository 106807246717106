/* Global styles */
* {
    -webkit-tap-highlight-color: transparent
}
body {
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    overflow-y: scroll !important
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5 {
      padding: 0px;
      margin: 0px;
  }
  .material-icons{
      margin-left:  5px !important;
      position: relative;
      top: -2px;
  }
  form{
      width: 100%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
  }
  p{
      font-size: 14px;
  }
  
  a{
      text-decoration: none !important;
      color: inherit;
  }
  /* Form fix */
  .form-fix{
      background: #fcfcfc;
      margin: 5px auto !important;
      box-sizing: border-box;
      width: 100%;
      border-radius: 3px;
      padding: 10px;
      border: 1px solid #ddd
  }
  a,
  button,
  input,
  textarea,
  select{
      outline: none !important;
      border: none;
      font-family: inherit;
      -webkit-tap-highlight-color: transparent
  }
  ::-webkit-input-placeholder{
      color: #CCCCCC;
  }
  ::-moz-placeholder{
      color: #CCCCCC;
  }
  :-ms-input-placeholder{
      color: #CCCCCC;
  }
  ::-ms-input-placeholder{
      color: #CCCCCC;
  }
  ::placeholder{
      color: #CCCCCC;
  }
  ::-webkit-scrollbar{
      width: 10px;
      height: 8px;
      background: transparent;
  }
  ::-webkit-scrollbar-thumb{
      background: #eee;
}
  i,
  .fa,
  .far,
  .fab,
  .fas{
      padding-right: 3px !important;
  }
  li{
      list-style: none;
  }
  img{
      max-width: 100%;
  }
  textarea {
      border-bottom: 1px solid #aaa;
  }
  .container{
      width: 90%;
      max-width: 2500px;
      margin: auto auto !important;
  }
  .in-container{
    width: 80%;
    max-width: 2000px;
    margin: auto auto !important;
    box-sizing: border-box
  }
  /*images*/
  .s-profile_pic{
      height: 30px;
      width: 30px;
      border-radius: 100%;
      border: 1.2px solid #fff;
      padding: 3px;
  }
  .m-profile_pic{
      height: 50px;
      width: 50px;
      border-radius: 100%;
      border: 1.2px solid #fff;
      padding: 3px;
  }
  .l-profile_pic{
      height: 50px;
      width: 50px;
      border-radius: 100%;
      border: 1.2px solid #fff;
      padding: 3px;
  }
  
  /*Grid system*/
  .full-width{
      width: 100%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
  }
  /*shadow*/
  .shadow_soft{
    -webkit-box-shadow: 0px 10px 30px rgba(0,0,0,0.05);	
    box-shadow: 0px 10px 30px rgba(0,0,0,0.05);	
  }
  .shadow_hard{
    -webkit-box-shadow: 0px 10px 30px rgba(0,0,0,0.08);	
    box-shadow: 0px 10px 30px rgba(0,0,0,0.08);	
  }
  .shadow.mat{
      box-shadow: 2px 1px 3px rgba(0,0,0,.08) !important;
  }
  
  /*Box models*/
  .border-box{
      box-sizing: border-box !important;
  }
  /*Layouts*/
  /*Grouped contents*/
  /*Pills buttons*/
  .pill{
      display: flex;
      border-radius: 3px;
  }
  .pill > *{
      background: #ddd;
      padding: 10px;
  }
  .pill > *:first-child{
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      padding-right: 8px !important;
      border-right: 1.2px solid #bbb;
      opacity: .6
  }
  .pill > *:last-child{
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
  }
  .grouped{
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0px !important;
      border: 1.2px solid #aaa;
      border-radius: 5px;
  }
  .grouped > *{
      margin: 0px !important;
      padding: 8px;
      flex: 1;
      text-align: center;
      border-right: 1px solid #ddd;
      text-align: center;
  
  }
  .grouped > *:first-child{
      margin-left: 0px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 0px !important;
      border-top-right-radius: 0px !important;
  }
  .grouped > *:last-child{
      margin-right: 0px;
      border-bottom-left-radius: 0px !important;
      border-top-left-radius: 0px !important;
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
  }
  /*Segments*/
  .top-segment{
      background-color: #fff;
      border: 1px solid rgba(0,0,0,.7);
      padding: 15px 10px;
      box-shadow: 2px 1px 2px rgba(0,0,0,.08);
      margin-bottom: 20px;
  }
  .top-segment.sub{
      padding: 10px !important;
  }
  .top-segment.sub h4{
      font-size: 12px !important;
      font-weight: normal;
  }
  .top-segment h4{
      font-size: 16px;
      font-weight: 600;
      color: #000;
      text-transform: uppercase !important;
      text-align: center;
  }
  /* DISPLAYS AND LAYOUTS */
  .block{
      display: block
  }
  .inline-block{
      display: inline-block
  }
  /*grids*/
  .grid{
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
  }
  .grid.apart{
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
  }
  .grid.around{
      -ms-flex-pack: distribute;
          justify-content: space-around;
  }
  .grid.even{
      -webkit-box-pack: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly;
  }
  .grid.centered{
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
  }
  .grid.align-c{
      -webkit-box-align: center !important;
          -ms-flex-align: center !important;
              align-items: center !important;
      -ms-flex-item-align: center !important;
          -ms-grid-row-align: center !important;
          align-self: center !important;
  }
  .grid.align-e{
      -webkit-box-align: end;
          -ms-flex-align: end;
              align-items: flex-end;
      -ms-flex-item-align: end;
          align-self: flex-end;
  }
  .grid.align-s{
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -ms-flex-item-align: start;
          align-self: flex-start;
  }
  .grid.equal > *{
      flex: 1;
  }
  .grid > * {
      margin: 5px;
  }
  .grid.gap-10 > * {
        margin: 10px;
  }
  .grid > *:first-child{
      margin-left: 0px !important;
  }
  
  /*Non collapsing grid*/
  
  .grid.no-wrap{
      -ms-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important;
  }
  .col{
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
          -ms-flex-direction: column !important;
              flex-direction: column !important;
  }
  .row{
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
          -ms-flex-direction: row !important;
              flex-direction: row !important;
  }
  /*Overflows*/
  .overflow-y{
      overflow-y: scroll;
  }
  .overflow-x{
      overflow-x: scroll;
  }
  /*Forms*/
   .row-2{
      min-height: 100px !important;
  }
   .row-4{
      min-height: 150px !important;
  }
  .row-6{
      min-height: 200px;
  }
   .row-8{
      min-height: 250px !important;
  }
   .row-10{
      min-height: 400px !important;
  }
  /*Buttons*/
  button{
      outline: none;
      border: 0px;
      cursor: pointer !important;
      padding: 10px;
      border-radius: 3px;
  }
  /*Colors*/
  .blue-t{
      color: #00a2ffec;
  }
  .blue.light-t{
      color: #4bafe9ec;
  }
  .green-t{
      color: #bdf046ec;
  }
  .chalk-t{
      color: #e70d85ec;
  }
  /*COLORS*/
  .primary-c{
      color: #a21318;
  }
  .black-t{
      color: #000
  }
  .red-t{
      color: #e51c23 !important;
  }
  .white-t{
      color: #fff !important;
  }
  .green-t{
      color: green !important;
  }
  .grey-t{
      color: #555;
  }
  .soft-grey{
      background-color: #f2f2f2;
  }
  .orange-t{
      color: orange
  }
  .soft-green-t{
      color: #DCE775
  }
  /*Background*/
  .primary-b{
      background-color: #a21318 !important;
      color: #fff !important;
  }
  .white{
      background-color: #fff;
  }
  .soft-green{
      background-color: #DCE775
  }
  .red{
      background-color: #ff5555;
      color: #fff;
  }
  .orange{
      background: orange
  }
  .blue{
      background-color: #00a2ffec;
      color: #fff;
  }
  .blue.light{
      background-color: #4bafe9ec;
      color: #555;
  }
  .green{
      background-color: #55dd55;
      color: #000;
  } 
  .chalk{
        background-color: #e70d85ec;
        color: #fff;
  }
  /*TEXTS*/
  .cap{
      text-transform: capitalize;
  }
  .upper{
      text-transform: uppercase;
  }
  .lower{
      text-transform: lowercase;
  }
  .centered-text{
      text-align: center !important;
  }
  .right-text{
      text-align: right !important;
  }
  .left-text{
      text-align: left  !important;
  }
  .bold-text-1{
      font-weight: 600;
  }
  .bold-text-2{
      font-weight: 800;
  }
  .margin-centered{
      margin: auto;
  }
  /*POSTIONS and visibility*/
  .relative{
      position: relative;
  }
  .absolute{
      position: absolute;
  }
  .absolute.top-0{
      top: 0px;
  }
  .absolute.right-0{
      right: 0px;
  }
  .absolute.left-0{
      left: 0px;
  }
  .absolute.bottom-0{
      bottom: 0px;
  }
  .hidden{
      visibility: hidden;
  }
  .no-display{
      display: none !important;
  }
  .display{
      display: block !important;
  }
  .no-opacity{
      opacity: 0;
  }
  .full-opacity{
      opacity: 1;
  }
  /*Overlays and modals*/
  .overlay{
      background-color: rgba(0,0,0,.3);
      position: fixed;
      top:0%;
      left: 0%;
      width: 100%;
      height: 100%;
      z-index: 999999 !important;
      transition: .4s ease-in;
  }
  .overlay.hidden{
      visibility: hidden;
      transition: .4s;
  }
  .overlay_b{
      background-color: rgba(0,0,0,.2);
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      height: 100%;
      z-index: 0 !important;
  }
  
  .my_modal{
      position: fixed;
      z-index: 999;
      background-color: #fff;
      left: 50%;
      top:50%;
      transform: translate(-50%, -50%);
      width: 90%;
      max-width: 800px;
      max-height: 80vh; 
      border-radius: 3px;
      transition: .4s ease-out;
      display: grid;
      align-items: space-between;
  }
  .my_modal.hidden{
      opacity: 0;
      visibility: hidden;
      transition: .4s ease-out;
      z-index: -999;
  }
  .my_modal #close{
      position: fixed;
      top: 0px;
      right: 10px;
      font-size: 30px;
      color: #ddd;
      cursor: pointer;
  }
  .my_modal #close:hover{
      color: #000;
  }
  /*.my_modal > div{
      padding: 20px;
  }*/
  .my_modal  .modal_head{
      text-align: center;
      font-size: 16px;
      border-bottom: 1.2px solid #ddd;
      padding: 20px;
      background-color: #fbfbfb;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
  }
  .my_modal  .modal_head h3{
      color: inherit;
  }
  .my_modal  .modal_foot{
      display: flex;
      justify-content: space-around;
      flex-wrap: nowrap;
      text-align: center;
      font-size: 16px;
      border-top: 1.2px solid #ddd;
      padding: 20px;
      margin-bottom: 10px;
      background-color: #fbfbfb;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
  }
  .my_modal  .modal_head h5{
      font-size: 16px;
      color: #000;
  }
  .my_modal .modal_content{
      padding: 20px;
      max-height: 50vh;
      overflow-y: scroll;
      margin: 10px 0px;
  }
  
  /*Messages*/
  .common.message{
      box-shadow: none;
      border: none;
      border: 1.2px solid ;
      padding: 15px 20px;
      font-size: 14px;
      width: 100%;
  }
  .common.message i{
      color: inherit !important;
  }
  .common.message.error{
      background-color: rgba(100,0,0,.05) !important;
      border: 2.5px solid rgba(100,0,0,.05);
  }
  .message{
      background: #fff !important;
      margin: 10px 0px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      box-sizing: border-box;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
              align-self: flex-start;
      width: 100%;
      height: auto;
      border-radius: 0px 3px 3px 0px;
      padding: 12px 10px;
      -webkit-box-shadow: 0px 5px 10px rgba(0,0,0,0.05);
              box-shadow: 0px 5px 10px rgba(0,0,0,0.05);
      border-left: 2px solid transparent;
      z-index: 10;
  }
  .message .des{
      display: flex;
      flex-direction: column
  }
  .message .des p{
      margin: 0px;
      padding: 0px;
  }
  .message .des h5{
      color: #000;
      display: block;
      font-size: 16px !important;
      margin: 0px 0px 5px 0px;
      padding: 0px;
      font-weight: 600;
      text-align: left
  }
  .message i{
      -ms-flex-item-align: center;
          -ms-grid-row-align: center;
          align-self: center;
      margin-right: 10px;
      color: #222;
      font-size: 25px;
  }
  .message  a {
      font-weight: 600;
      padding: 5px;
      border-radius: 3px;
      color: #fff;
  }
  /*Variants*/
  .message.basic{
      border-color: #555;
  }
  .message.basic i{
      color: #555;
  }
  .message.error {
      border-color: #e51c23;
  }
  .message.error i{
      color: #e51c23;
  }
  .message.success{
      border-color: #4CAF50;
  }
  .message.success i{
      color: #4CAF50;
  }
  .message.info{
      border-color: orange;
  }
  .message.info i{
      color: orange;
  }
  /*Dismissable message*/
  .dismissable.message{
      position: relative;
  }
  .dismissable.message .close{
      position: absolute;
      right: -3px;
      top: -5px;
      color: red;
      font-size: 14px !important;
      cursor: pointer;
      padding: 3px 5px 3px 3px;
      background-color: #fff;
      -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
              box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      border-radius: 100%;
      height: 20px;
      width: 20px;
      text-align: center;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
  }
  .dismissable.message .close:hover{
      background-color: red;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      color: #fff;
  }
  .scaleIn{
      -webkit-transform: scale(0);
          -ms-transform: scale(0);
              transform: scale(0);
      opacity: 0;
      -webkit-transition: .3s ease-out;
      -o-transition: .3s ease-out;
      transition: .3s ease-out;
  }
  .scaleOut{
      -webkit-transform: scale(3);
          -ms-transform: scale(3);
              transform: scale(3);
      opacity: 0;
      -webkit-transition: .3s ease-out;
      -o-transition: .3s ease-out;
      transition: .3s ease-out;
  }
  .flyOutLeft{
      -webkit-transform: translateX(100%);
          -ms-transform: translateX(100%);
              transform: translateX(100%);
      opacity: 0;
      -webkit-transition: .3s ease-out;
      -o-transition: .3s ease-out;
      transition: .3s ease-out;
  }
  .flyOutRight{
      -webkit-transform: translateX(100%);
          -ms-transform: translateX(100%);
              transform: translateX(100%);
      opacity: 0;
      -webkit-transition: .3s ease-out;
      -o-transition: .3s ease-out;
      transition: .3s ease-out;
  }
  .flipHor{
      -webkit-transform: rotateY(-180deg);
              transform: rotateY(-180deg);
      opacity: 0;
      -webkit-transition: .3s ease-out;
      -o-transition: .3s ease-out;
      transition: .3s ease-out;
  }
  .flipHorHide{
      -webkit-transform: rotateY(-180deg) scale(0);
              transform: rotateY(-180deg) scale(0);
      opacity: 0;
      -webkit-transition: .5s ease-out;
      -o-transition: .5s ease-out;
      transition: .5s ease-out;
      position: absolute;
      top: -1000px;
  }
  .flipVer{
      -webkit-transform: rotateX(180deg);
              transform: rotateX(180deg);
      opacity: 0;
      -webkit-transition: .3s ease-out;
      -o-transition: .3s ease-out;
      transition: .3s ease-out;
  }
  .unallowed{
      -webkit-animation: shake .5s;
              animation: shake .5s;
  }
  @-webkit-keyframes shake{
      0%{
          -webkit-transform: translateX(-30px);
                  transform: translateX(-30px);
      }
      20%{
          -webkit-transform: translateX(30px);
                  transform: translateX(30px);
      }
      30%{
          -webkit-transform: translateX(-30px);
                  transform: translateX(-30px);
      }
      40%{
          -webkit-transform: translateX(30px);
                  transform: translateX(30px);
      }
      50%{
          -webkit-transform: translateX(-30px);
                  transform: translateX(-30px);
      }
      60%{
          -webkit-transform: translateX(30px);
                  transform: translateX(30px);
      }
      70%{
          -webkit-transform: translateX(-30px);
                  transform: translateX(-30px);
      }
      80%{
          -webkit-transform: translateX(30px);
                  transform: translateX(30px);
      }
      90%{
          -webkit-transform: translateX(-30px);
                  transform: translateX(-30px);
      }
      100%{
          -webkit-transform: translateX(0px);
                  transform: translateX(0px);
      }
  }
  @keyframes shake{
      0%{
          -webkit-transform: translateX(-30px);
                  transform: translateX(-30px);
      }
      20%{
          -webkit-transform: translateX(30px);
                  transform: translateX(30px);
      }
      30%{
          -webkit-transform: translateX(-30px);
                  transform: translateX(-30px);
      }
      40%{
          -webkit-transform: translateX(30px);
                  transform: translateX(30px);
      }
      50%{
          -webkit-transform: translateX(-30px);
                  transform: translateX(-30px);
      }
      60%{
          -webkit-transform: translateX(30px);
                  transform: translateX(30px);
      }
      70%{
          -webkit-transform: translateX(-30px);
                  transform: translateX(-30px);
      }
      80%{
          -webkit-transform: translateX(30px);
                  transform: translateX(30px);
      }
      90%{
          -webkit-transform: translateX(-30px);
                  transform: translateX(-30px);
      }
      100%{
          -webkit-transform: translateX(0px);
                  transform: translateX(0px);
      }
  }
  /*PADDING and margins*/
  .padded-side{
      padding: 0px 10px
  }
  .padded-arial{
      padding: 10px 0px
  }
  .padded-5{
      padding: 5px;
  }
  .padded-10{
      padding: 10px;
  }
  .padded-20{
      padding: 20px !important;
  }
  .no-padding{
      padding: 0px !important;
  }
  .margin-5{
      margin: 5px;
  }
  .margin-10{
      margin: 10px;
  }
  .margin-20{
      margin: 20px;
  }
  .margin-right-5{
      margin-right: 5px;
  }
  .margin-right-10{
      margin-right: 10px;
  }
  .margin-left-5{
      margin-left: 5px !important;
  }
  .margin-top-5{
      margin-top: 5px;
  }
  .margin-top-10{
    margin-top: 10px;
}
  .margin-bottom-5{
      margin-bottom: 5px;
  }
  .margin-bottom-10{
    margin-bottom: 10px;
}
  .no-margin{
      margin: 0px !important;
  }
  /*divider*/
  .or{
      height: 30px;
      width: 30px;
      border-radius: 100%;
      background: #eee;
      text-align: center;
      padding: 4px 0px;
      font-size: 14px;
      position: relative;
      color: #aaa;
  }
  .or:before{
      content: " ";
      position: absolute;
      bottom: 40px;
      left: 50%;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%);
      height: 200px;
      width: 1px;
      background-color: #eee;
  
  }
  .or:after{
      content: " ";
      position: absolute;
      top: 40px;
      left: 50%;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%);
      height: 200px;
      width: 1px;
      background-color: #eee;
  
  }
  hr{
      opacity: .5;
  }
  
  /*<!-- custom select dropdown -->*/
      .select-wrap input ::-webkit-input-placeholder{
          font-size: 14px !important;
          color: #DDDDDD !important;
      }
      .select-wrap input ::-moz-placeholder{
          font-size: 14px !important;
          color: #DDDDDD !important;
      }
      .select-wrap input ::-ms-input-placeholder{
          font-size: 14px !important;
          color: #DDDDDD !important;
      }
      .select-wrap input ::placeholder{
          font-size: 14px !important;
          color: #DDDDDD !important;
      }
  /*	.select-wrap ::-webkit-scrollbar{
          width:5px !important;
          background-color: #AAAAAA;
      }
      .select-wrap ::-webkit-scrollbar-thumb{
          background: #007aff;
          border-radius: 50px;
      }*/
      .select-wrap{
          width: 100%;
          max-width: 200px;
          border: 1px solid #ddd;
          background-color: #FFFFFF;
          padding: 3px 5px;
          position: relative !important;
          cursor: pointer;
          transition: .5s;
          border-radius: 5px;
          color: initial !important;
          font-size: 14px;
          align-self: center !important;
          max-height: 45px;
      }
      .border-b{
          background: transparent;
          border-top: none;
          border-right: none;
          border-left: none;
          border-radius: 0px;
          border-bottom: 1px solid #ddd;
      }
      .border-b input[type='text']{
          background-color: transparent;
          padding: 0px !important;
          color: #aaa;
          font-size: 14px;
          border: none !important;
      }
      .border-b:hover{
          border-top: none !important;
          border-right: none !important;
          border-left: none !important;
          border-bottom: 1px solid ;
      }
      .select-wrap:hover{
          border: 1px solid #007aff;
          transition: .5s
      }
      .select-wrap .text .fa{
          right: 5px;
          position: absolute;
          top: 35%;
          font-size: 16px;
          color: #aaa;
          transition: .5s;
      }
      .select-wrap input{
          cursor: pointer;
          text-transform: capitalize;
          border:none !important;
      }
  
      .select-wrap:hover > .option{
          visibility: visible;
          opacity: 1;
          top: 108%;
          transition: .3s;
          z-index: 999;
      }
      .select-wrap:hover .text .fa{
          -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
          transition: .5s;
      }
      .select-wrap .option{
          position: absolute;
          width: 100%;
          box-sizing: border-box;
          left: 0px;
          box-shadow: 0px 5px 30px rgba(0,0,0,.1);
          top: 60%;
          opacity: 0;
          visibility: hidden;
          transition: .1s ease-in;
          max-height: 250px;
          overflow-x: hidden;
          overflow-y: scroll;
          background-color: #fff;
      }
      .hide{
          top: 60% !important;
          opacity: 0 !important;
          visibility: hidden !important;
      }
      .select-wrap .option li{
          display: block !important;
          position: relative !important;
          background: #fff;
          color: #555;
          width: 100%;
          box-sizing: border-box;
          overflow: hidden;
          transition: .5s;
          left: -90px;
          padding: 10px 15px;
          border-bottom: 1px solid #EEEEEE;
          background: #FFFFFF;
  
      }
      .select-wrap .option li .fa{
          color: #aaa;
          padding-right: 8px;
      }
      .select-wrap .option li:first-child{
          transition: .4s;
      }
      .select-wrap .option li:nth-child(even){
          transition: .6s;
      }
      .select-wrap .option li:nth-child(odd){
          transition: .9s;
      }
      .select-wrap .option li:hover{
          opacity: .8;
      }
      .select-wrap:hover .option li{
          left: 0px;
      }
      .select-wrap:hover .option li:first-child{
          transition: .4s;
      }
      .select-wrap:hover .option li:nth-child(2){
          transition: .6s;
      }
      .select-wrap:hover .option li:nth-child(3){
          transition: .9s;
      }
      .select-wrap .option .selected{
          background: #007aff;
          color: #fff;
          box-shadow: inset 0px 0px 30px rgba(0,0,0,.3)
      }
      .select-wrap .option li input{
          width: 100%;
          height: 100%;
          top:0px;
          position: absolute !important;
          opacity: 0;
          cursor: pointer !important;
      }
  
  /*specail tagas*/
  code{
      background: #eee;
      color: #222;
      padding: 3px 10px ;
      border-radius: 10px;
  }
  
  /*dropdown*/
  .dropdown{
      visibility: hidden;
      opacity: 0;
      position: absolute;
      background-color: #fff;
      width: 250px;
      z-index: 9;
      top: 250%;
      left: 50%;
      -webkit-transform: translateX(-50%) rotateX(180deg);
              transform: translateX(-50%) rotateX(180deg);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      padding-top: 15px;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      overflow: hidden;
  }
  li:hover > .dropdown{
      visibility: visible;
      opacity: 1;
      -webkit-transition: .5s;
      -o-transition: .5s;
      transition: .5s;
      top: 100%;
      -webkit-transform: translateX(-50%) rotateX(0deg);
              transform: translateX(-50%) rotateX(0deg);
  }
  .dropdown a{
      padding: 10px 20px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border-bottom: 1px solid #eee
  }
  .dropdown a:hover{
      background-color: #222;
      padding: 10px 20px;
      color: #fff !important;
  }
  /*Intro*/
   .sec_intro{
      text-align: center;
      background: #fff;
      padding: 20px;
      margin: 0px auto;
      border-radius: 3px;
      border-left: 5px solid transparent;
      -webkit-box-shadow: 0px 15px 30px rgba(0,0,0,.07);
              box-shadow: 0px 15px 30px rgba(0,0,0,.07)
  }
  
  /*Breadcrum*/
  .breadcrumb{
      padding: 20px 10px;
  }
  .breadcrumb li{
      display: inline;
      position: relative;
  }
  .breadcrumb li:after{
      content: ">";
      color: orange;
      padding: 0px 5px;
  }
  .breadcrumb li:last-child{
      color: #e70d85ec;
  }
  .breadcrumb a:last-child {
      color: #222 !important;
  }
  .breadcrumb li:last-child:after{
      display: none;
  }
  .breadcrumb li a{
      font-size: 16px;
      color: #777777;
      text-transform: capitalize;
  
  }
  
  /*tabs*/
  .tabbed.section .tab_control{
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      top: 1px;
      left: 0px;
  }
  .tabbed.section .tab_control .prim{
      background: #777;
      color: #eee;
      transition: .2s;
      border: 1.5px solid transparent;
  }
  .tabbed.section .tab_control .inverted{
      background: #fff;
      color: #222;
      transition: .5s;
      border: 1px solid #777;
      border-bottom: 0px;
  }
  .tabbed.section .tab_content{
      padding: 20px;
      min-height: 400px;
      display: none;
      border: 1.2px solid #777;
      transition: .5s;
  }
  .tabbed.section .tab_content:nth-child(2){
      display: block;
  }
  
  /*Appearances*/
  .hide{
      display: none;
  }
  .show{
      display: block !important;
  }
  
  /*Placeholders*/
  .placeholder{
      background: #eee;
      margin: 5px 0px;
      width: 100%;
      display: flex;
      align-items: center;
      font-weight: 600;
      padding: 20px;
      border-radius: 5px;
      box-shadow: inset 0px 0px 50px rgba(0,0,0,.2);
  }
  .placeholder-2{
      text-align: center;
      background-color: #fff;
  }
  .placeholder-2 .fa{
      font-size: 60px;
      color: #DDDDDD;
  }
  .placeholder-2  p{
      max-width: 400px;
      margin: 20px auto;
  }
  .placeholder-2 .btn{
      margin-top: 10px;
  }
  .placeholder > *:first-child{
      flex: 1;
      max-width: 50px;
      
  }
  .placeholder > *:last-child{
      flex: 3;
      padding-left: 20px;
      color: #777;
  }
  .placeholder > *:last-child a{
      text-decoration: none !important;
  }
  /*TABLES*/
  
  .table{
      display: table;
      box-shadow: 2px 1px 2px rgba(0,0,0,.08);
      padding: 10px;
      border-collapse:  collapse;
      width: 100%;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: #fbfbfb;
  }
  .table thead th{
      font-size: 14px;
      text-align: left !important;
      padding: 8px !important;
      background-color: #fbfbfb !important;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      color: #555;
      text-transform: uppercase;
  }
  .table tbody tr{
      background-color: #fff;
      font-size: 14px;
      color: #222 !important;
      border-bottom: 1px solid #f2f2f2 !important
  }
  .table tr:hover{
      background-color: #fbfbfb;
  }
  .table tr td{
      color: #222;
      padding: 5px 8px;
  }
  .table-wrap{
      background-color: #E8E8F0 !important;
      border: 1px solid #ddd;
  }
  .table-wrap .table-label{
      padding: 8px 0px;
      color: #222;
      font-size: 16px;
  }
  .table-wrap  .table-foot{
      padding: 10px;
      background-color: #fbfbfb !important;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
  }
  .table-wrap  .table-foot .grouped > *{
      border: 1px solid #dddddd !important;
  }
  .table-wrap  .table-foot .grouped > *:first-child{
      border-right: none !important;
  }
  
  
  /*psuedo css*/
  .psue li{
      position: relative;
  }
  .psue li:before{
      list-style: circle !important;
      font-family: "Font Awesome 5 Free";
      content: "\f02e";
      padding-right: 8px;
      color: #550000;
  }
  
  /*Timelines*/
  .timelines > *{
      position: relative;
      padding-left: 8px !important;
  }
  .timelines > *:before{
      content: "";
      position: absolute;
      height: 8px;
      width: 8px;
      border-radius: 100%;
      left: -8px;
      top: 2px;
      background-color: #bbb
  }
  .timelines > *:after{
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      left: -4.5px;
      top: 10px;
      bottom: 0px;
      background-color: #bbb
  }
  /*pagination*/
  .pagination{
      width: 100%;
      margin: 50px auto;
      text-align: center;
  }
  .pagination a{
      color: #555555 !important;
      padding: 10px 15px;
      box-sizing: border-box;
      border-radius: 5px;
      margin: 5px;
      transition: .3s ease-out;
  }
  .pagination a:hover{
      background-color: #236df8 !important;
      color: #FFFFFF !important;
  }
  .pagination .active{
      background-color: #236df8;
      color: #FFFFFF !important;
  }
  .pagination a:first-child,
  .pagination a:last-child{
      background-color: transparent;
      border: 1px solid #CCCCCC;
      color: #AAAAAA !important;
  }
  /*Animatinos*/
  /*Pulse*/
  .pulse {
    overflow: visible;
    position: relative; 
  }
  
  .pulse::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: inherit;
    border-radius: inherit;
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s;
    transition: opacity .3s, transform .3s, -webkit-transform .3s;
    -webkit-animation: pulse-animation 2s cubic-bezier(0.24, 0, 0.38, 1) infinite;
            animation: pulse-animation 2s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    /*z-index: -1;*/
  }
  
  @-webkit-keyframes pulse-animation {
    0% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      opacity: 0;
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }
  }
  
  @keyframes pulse-animation {
    0% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      opacity: 0;
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
    }
  }
  /*Lines and dividers*/
  .divider{
      min-height: 1px;
      margin: 3px 0px;
      height: 1px;
      width: 100%;
      background-color: #eee;
      box-sizing: border-box;
  }
  .vertical-divider{
      position: relative;
      width: 1px !important;
      margin: 0px 5px;
  }
  .vertical-divider:after{
      content: "";
      position: absolute;
      width: 1.2px;
      background-color: #eee;
      top: 0px;
      bottom: 0px;
      height: 100%
  }
  
  
  /*States*/
  .not-allowed{
      cursor: not-allowed !important;
  }
  /*Borders*/
  .bordered{
      border-radius: 5px;
  }
  .very.bordered{
      border-radius: 50px;
  }
  .no-border{
      border-radius: 0px !important;
  }
  /*TOOPTIPS AND HELP TIPS*/
  .help-tip{
      position: absolute;
      height: 25px;
      width: 25px;
      border: 1px solid #ddd;
      box-sizing: border-box;
      padding: 5px 0px 0px 5px;
      font-size: 12px;
      text-align: center;
      border-radius: 100%;
  }
  .help-tip i{
      color: #aaa;
      cursor: pointer;
  }
  .help-tip:hover > .content{
      visibility: visible;
      transition: .5s;
      transition-delay: .7s;
  }
  .help-tip .content{
      text-align: left;
      visibility: hidden;
      background: #fff;
      padding: 10px;
      border-radius: 5px;
      width: auto;
      min-width: 150px;
      box-shadow: 0px 10px 30px rgba(0,0,0,.08);
      transition: .5s;
  }
  /*Hovers and cursors*/
  .hover-black{
      transition: .3s;
  }
  .hover-black:hover{
      color: #000;
      transition: .3s;
  }
  .link{
      cursor: pointer !important;
  }
  
  
  
  
  /*Responsiveness*/
  @media(max-width: 768px){
      .table{
          display: flex;
          flex-direction: column;
      }
      .table  *{
          display: flex;
          flex-direction: column;
      }
      .table tbody tr{
          background-color: #fff;
          padding: 10px !important;
          font-size: 12px;
          color: #222 !important
      }
      .table tbody tr:nth-child(even){
          background-color: #EEEEEE
      }
  }
  
  @media(max-width: 600px){
      .tabbed.section .tab_control .prim{
          padding: 5px;
          font-size: 12px;
          font-weight: 600px;
      }
  }
  @media(max-width: 400px){
      .tabbed.section .tab_control .prim{
          padding: 5px;
          font-size: 10px;
      }
      .tabbed.section .tab_control{
          top: 5px;
      }
      /*Pagination*/
      .pagination a{
          padding: 8px;
          font-size: 12px;
          margin: 3px;
      }
  }
  