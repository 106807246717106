html{
  -webkit-font-smoothing: antialiased !important;
}
body{
    font-family: "Lato";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #fff;
    max-width: 3000px;
    margin: 0px auto;
}
button{
  font-family: "Lato";
}
/* COLOR VARIABLES */
:root{
  --primary-color: #5555ff;
  --light-blue: #4bafe9;
  --backdrop: #fcfcfc;
  --faded-blue: rgb(236, 241, 243);
  --darker-blue: #33467b;
  --dark-green: #4caf50; 
  --light-green: #20d046;
  --orange: orange;
  --purple: #8c20d0;
  --red: #e51c23;
  --dark-gray: #505e67;
  --primary-grey: #aaa;
  --secondary-color: rgba(0, 0, 0, 0.8); 
  --black: #222222;
  --soft-shadow: 1px 1px 1px rgba(0,0,0,.08) ;
  --mid-shadow: 0px 10px 30px rgba(0,0,0,.08) ;
  --vs-down-shadow: 1px 5px 30px rgba(0,0,0,.07) 
}
/* COLORS */
.purple{
  background: var(--purple)
}
.purple-t{
  color: var(--purple)
}
.dark-blue{
  background: var(--darker-blue)
}
.dark-blue-t{
  color: var(--darker-blue)
}


/* GLOBAL DEFAULTS */
img{
  max-width: 100% !important;
}
.container{
  width: 95%;
  margin: auto;
}
@media screen and (min-width: 1000px){
  .container{
    max-width: 1500px;
    width: 90%;
  }
}
@media(max-width: 650px){
  .bezless.container{
    width: 100% !important
  }
  .container{
    width: 97%;
  }
  .in-container{
    width: 90%
  }
}
button:hover{
  opacity: .9;
}
button:disabled{
  background: #aaa !important;
color: #000 !important;
  cursor: not-allowed !important;
}
/* FORMS */
.form-fix{
  padding: 15px 10px;
  background: #fff;
  transition: .3s;
}
.form-fix:focus{
  border-color: var(--primary-color);
  transition: .3s;
}
.form-fix:disabled{
  background: #f2f2f2
}
* {
  transition: .3s !important;
}